import { Heading, Link, Text } from "@chakra-ui/react";
import { Layout } from "../src/components/common/Layout";
import { paths } from "../src/utils/paths";
import { useAmplitude } from "../src/hooks/utils/useAmplitude";
import NextLink from "next/link";
import React, { useEffect } from "react";

export const Error404: React.FunctionComponent = ({ }) => {
  const { events, logEvent } = useAmplitude();

  useEffect(() => {
    logEvent(events.error.loadPage({ statusCode: 404 }));
  }, [logEvent, events]);

  return (
    <Layout title="Page not found" containerWidth="md" enableSidebar requireAuthenticated={false}>
      <Heading mb={5}>Page not found</Heading>
      <Text mb={5}>We{"'"}re not sure how you ended up here!</Text>
      <NextLink href={paths.all} passHref>
        <Link as="span" onClick={() => logEvent(events.error.clickReturn)}>Return</Link>
      </NextLink>
    </Layout>
  );
};

export default Error404;
